import request, { gql } from 'graphql-request'
import { DEFAULT_HYGRAPH_LANG, toLocalesHygraph } from '../utils/utils'

const GRAPHQL_API = process.env.HYGRAPHQL_API

export const getGlossaries = async (locale: string = DEFAULT_HYGRAPH_LANG) => {
  if(!GRAPHQL_API) return []
  const query = gql`
    query GetGlossaries($locales: [Locale!]!) {
      glossariesConnection (`+ /*(SITE_IS_PROD ? 'where: { production: true }, ' : '') +*/ `locales: $locales, orderBy: name_ASC, first: 100) {
        edges {
          node {
            id
            key
            name
            shortDesc
            longDesc
          }
        }
      }
    }
  `
  return request(GRAPHQL_API, query, { locales: toLocalesHygraph(locale) }).then((result: any) => {
    return result.glossariesConnection.edges.map((edge: { node: any }) => edge.node)
  })
}