import { siteConfig } from '@/config/site.config'
import { cn } from '@/lib/utils/utils'
import Link from 'next/link'
import { FaRegCircleQuestion } from 'react-icons/fa6'
import { getI18n, getLocalizedUri, I18nDictionary, useI18nValue } from '../i18n/lang-context'
import Tooltip from '../ui/tooltip'
import { Glossary, readGlossaryEntry } from './glossary'

const I18N: I18nDictionary = {
  goToGlossary: {
    en: 'Navigate to full definition in the glossary',
    fr: 'Naviguer vers la définition complète dans le glossaire'
  }
}

export const getGlossaryLink = (key: string) => siteConfig.links.site + getLocalizedUri('/glossary#' + key)

export default function GlossaryLink({ _key, className = '', glossary, children }: { _key: string, className?: string, glossary: Glossary, children?: any }) {

  const i18n = getI18n(I18N)

  const i18nValue = useI18nValue()
  const glossaryEntry = readGlossaryEntry(_key, glossary)

  return !glossaryEntry ? <>{children}</> :
    <span className={cn('relative inline-flex items-start', className)} >
      <Tooltip content={i18nValue(glossaryEntry.shortDesc)}>
        <span className='mr-2 cursor-help border-b-[2px] border-dotted border-slate-500/50 transition-all hover:border-primaryBright'>
          {children || i18nValue(glossaryEntry.name)}
        </span>
      </Tooltip>
      <Link
        href={getGlossaryLink(_key)}
        title={i18n.goToGlossary}
        className='absolute -right-2 -top-0 -mt-2 p-1 text-xs font-light text-primary opacity-50 transition-all hover:font-[500] hover:opacity-100 dark:text-primaryBright'>
        <FaRegCircleQuestion size={12} />
      </Link>
    </span>
}
